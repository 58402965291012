import React from "react";
import "./StaticContent.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/org-logo.png";

const TandC = () => {
  return (
    <>
      <div className="static-content container">
        <div className="static-content-logo">
          <Link to="/">
            <img src={Logo} alt="logo" className="" />
          </Link>
        </div>
        <div className="static-content-title common-heading">
          Terms & Conditions
        </div>
        <div className="horizontal-line"></div>
        <div className="static-content-text">
          <div style={{ marginTop: "30px" }}>Last Updated: 15 September 2023</div>
          <div className="static-content-text-heading common-header-tag">
            1. Introduction
          </div>
          <div className="static-content-text-desc common-paragraph2">
            Welcome to Fitnex. By accessing or using our application (the
            "App"), you agree to comply with and be bound by these Terms &
            Conditions. If you do not agree to these Terms, please do not use
            our App.
          </div>

          <div className="static-content-text-heading common-header-tag">
            2. Use of the App
          </div>
          <div className="static-content-text-desc common-paragraph2">
            You must be at least 13 years old to use our App. You
            agree to use the App only for lawful purposes and in accordance with
            these Terms.
          </div>

          <div className="static-content-text-heading common-header-tag">
            3. Account Registration
          </div>
          <div className="static-content-text-desc common-paragraph2">
            To use certain features of the App, you may need to register and
            create an account. You agree to provide accurate and complete
            information during registration and to update such information to
            keep it accurate and complete. You are responsible for safeguarding
            your account information and for any activities or actions under
            your account.
          </div>

          <div className="static-content-text-heading common-header-tag">
            4. User Content
          </div>
          <div className="static-content-text-desc common-paragraph2">
            You may be able to upload, post, or otherwise submit content (e.g.,
            exercise routines, progress photos) to the App. You retain ownership
            of your content but grant us a worldwide, royalty-free, perpetual,
            and non-exclusive license to use, reproduce, modify, and display
            your content in connection with the App.
          </div>

          <div className="static-content-text-heading common-header-tag">
            5. Prohibited Conduct
          </div>
          <div className="static-content-text-desc common-paragraph2">
            You agree not to engage in any of the following prohibited
            activities:
            <div>
              <div>· Violating any applicable laws or regulations.</div>
              <div>
                · Using the App to transmit harmful or offensive content.
              </div>
              <div>
                · Attempting to gain unauthorized access to other accounts or
                systems.
              </div>
              <div>· Interfering with the App's operation or security.</div>
            </div>
          </div>

          <div className="static-content-text-heading common-header-tag">
            6. Disclaimers
          </div>
          <div className="static-content-text-desc common-paragraph2">
            The App and its content are provided "as is" and "as available"
            without warranties of any kind, either express or implied. We do not
            warrant that the App will be uninterrupted or error-free.
          </div>

          <div className="static-content-text-heading common-header-tag">
            7. Limitation of Liability
          </div>
          <div className="static-content-text-desc common-paragraph2">
            To the fullest extent permitted by law, we are not liable for any
            indirect, incidental, special, or consequential damages arising from
            your use of the App.
          </div>

          <div className="static-content-text-heading common-header-tag">
            8. Termination
          </div>
          <div className="static-content-text-desc common-paragraph2">
            We reserve the right to terminate or suspend your access to the App
            at our sole discretion, with or without cause, and with or without
            notice.
          </div>

          <div className="static-content-text-heading common-header-tag">
            9. Changes to These Terms
          </div>
          <div className="static-content-text-desc common-paragraph2">
            We may update these Terms from time to time. The most current
            version will be posted on our App. Your continued use of the App
            constitutes acceptance of any changes.
          </div>

          <div className="static-content-text-heading common-header-tag">
            10. Governing Law
          </div>
          <div className="static-content-text-desc common-paragraph2">
            These Terms are governed by and construed in accordance with the
            laws of Rajasthan/India without regard to its conflict of laws
            principles.
          </div>

          <div className="static-content-text-heading common-header-tag">
            11. Contact Us
          </div>
          <div className="static-content-text-desc common-paragraph2">
            If you have any questions about these Terms, please contact us at
            "support@spanidea.com". BY USING THIS WEBSITE OR OUR SERVICES, YOU INDICATE
            YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS.
          </div>
        </div>
      </div>
    </>
  );
};

export default TandC;
