import React from "react";
import Tag from "./Tag";
import playStore from "../../assets/play-store.png";
import appStore from "../../assets/app-store.png";
import downloadAppBg from "../../assets/download-app-bg.png";

const AppBanner = () => {
  return (
    <div className="container app-banner">
      <div className="download-app">
        <div className="download-app-Bg">
          <img src={downloadAppBg} alt="downloadAppBg"></img>
          <div className="download-app-text">
            <Tag title={"download app"} />
            <div className="download-app-heading common-heading">
              Realtime voice instructions while doing your workouts.
            </div>
            <div className="download-app-links">
              <img
                src={playStore}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=comyogaa.google.mlkit.vision.yogaa&pcampaignid=web_share",
                    "_blank"
                  );
                }}
                alt="playStore"
              ></img>
              <img src={appStore} alt="appStore"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppBanner;
