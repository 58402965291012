import React from "react";
import Tag from "../Common/Tag";
import "./ContactUs.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        // Use setTimeout to ensure the element is rendered
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 1000);
      }
    }
  }, [location.hash]);

  return (
    <div className="contact-us container" id="contact-us">
      <div className="contact-us-tag">
        <Tag title="contact us" />
      </div>
      <div className="contact-us-heading common-heading">Connect With Us</div>
      {/* <div className="common-heading form-title">Get in Touch</div>
      <div className="form-tagline">We’d love to hear from you!</div> */}
      <form
        onSubmit={() => {
          "submit";
        }}
        className="contact-form"
      >
        <div className="form-row">
          <div className="form-group common-paragraph2">
            <label htmlFor="fname">First Name</label>
            <input
              type="text"
              id="fname"
              // value={name}
              // onChange={(e) => setName(e.target.value)}
              placeholder="Enter first name"
              required
            />
          </div>
          <div className="form-group common-paragraph2">
            <label htmlFor=";name">Last Name</label>
            <input
              type="text"
              id="lname"
              // value={name}
              // onChange={(e) => setName(e.target.value)}
              placeholder="Enter last name"
              required
            />
          </div>
          <div className="form-group common-paragraph2">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              // value={email}
              // onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
              required
            />
          </div>
        </div>

        <div className="form-group common-paragraph2">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            // value={message}
            // onChange={(e) => setMessage(e.target.value)}
            placeholder="Write your message..."
            required
          ></textarea>
        </div>
        <div className="form-group common-paragraph2 form-group-checkbox">
          <label>
            <input
              type="checkbox"
              //   checked={termsAccepted}
              //   onChange={(e) => setTermsAccepted(e.target.checked)}
              required
            />
            I accept the{" "}
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </a>
          </label>
        </div>
        <button type="submit" className="btn">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
