import React from "react";
import "./StaticContent.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/org-logo.png";

const PrivacyPolicy = () => {
  return (
    <div className="static-content container">
      <div className="static-content-logo">
        <Link to="/">
          <img src={Logo} alt="logo" className="" />
        </Link>
      </div>
      <div className="static-content-title common-heading">Privacy Policy</div>
      <div className="horizontal-line"></div>
      <div className="static-content-text">
        <div className="static-content-text-heading common-header-tag">
          1. Introduction
        </div>
        <div className="static-content-text-desc common-paragraph2">
          This Privacy Policy explains how Fitnex collects, uses, and shares
          information about you when you use our application (the "App"). By
          using our App, you consent to the practices described in this Privacy
          Policy.
        </div>

        <div className="static-content-text-heading common-header-tag">
          2. Information We Collect
        </div>
        <div className="static-content-text-desc common-paragraph2">
          We at SpanIdea Systems respect your desire to protect personal
          information. This page reveals what sort of information we may ask you
          for, and in what situations.
          <div>
            <div>
              · Personal Information: We may collect personal information that
              you provide to us, such as your name, email address, and fitness
              data.
            </div>
            <div>
              · Usage Data: We collect information about how you interact with
              the App, including your usage patterns, device information, and IP
              address.
            </div>
            <div>
              · Cookies and Tracking Technologies: We use cookies and similar
              technologies to track your activity and preferences.
            </div>
          </div>
        </div>

        <div className="static-content-text-heading common-header-tag">
          3. How We Use Your Information
        </div>
        <div className="static-content-text-desc common-paragraph2">
          We use the information we collect to:
          <div>
            <div>· Provide and improve the App.</div>
            <div>
              · Customize your experience and provide personalized content.
            </div>
            <div>
              · Communicate with you, including sending updates and promotional
              materials.
            </div>
            <div>
              · Analyze usage and trends to enhance the App's functionality.
            </div>
          </div>
        </div>

        <div className="static-content-text-heading common-header-tag">
          4. Sharing Your Information
        </div>
        <div className="static-content-text-desc common-paragraph2">
          We may share your information with:
          <div>
            <div>
              · Service Providers: Third-party vendors who perform services on
              our behalf, such as hosting and analytics.
            </div>
            <div>
              · Legal Requirements: If required by law or to protect our rights
              and safety.
            </div>
          </div>
        </div>

        <div className="static-content-text-heading common-header-tag">
          5. Security
        </div>
        <div className="static-content-text-desc common-paragraph2">
          We implement reasonable measures to protect your information from
          unauthorized access, but no security system is impenetrable. We cannot
          guarantee the absolute security of your information.
        </div>

        <div className="static-content-text-heading common-header-tag">
          6. Your Choices
        </div>
        <div className="static-content-text-desc common-paragraph2">
          <div>
            <div>
              · Access and Correction: You may access and update your personal
              information by contacting us.
            </div>
            <div>
              · Opt-Out: You can opt-out of receiving promotional communications
              by following the instructions in those communications.
            </div>
          </div>
        </div>

        <div className="static-content-text-heading common-header-tag">
          7. Children's Privacy
        </div>
        <div className="static-content-text-desc common-paragraph2">
          The App is not intended for use by children under 13 years. We do
          not knowingly collect personal information from children.
        </div>

        <div className="static-content-text-heading common-header-tag">
          8. Changes to This Privacy Policy
        </div>
        <div className="static-content-text-desc common-paragraph2">
          We may update this Privacy Policy from time to time. The most current
          version will be posted on our App. Your continued use of the App
          constitutes acceptance of any changes.
        </div>

        <div className="static-content-text-heading common-header-tag">
          9. Contact Us
        </div>
        <div className="static-content-text-desc common-paragraph2">
          If you have any questions about this Privacy Policy, please contact us
          at "support@spanidea.com".
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
