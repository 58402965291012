import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Hero from "./Components/Hero/Hero";
import About from "./Components/About/About";
import Features from "./Components/Features/Features";
import Technology from "./Components/Technology/Technology";
import Footer from "./Components/Footer/Footer";
import Testimonials from "./Components/Testimonials/Testimonials";
import ScrollToTop from "./Components/Common/ScrollToTop";
import TandC from "./Components/StaticContent/TandC";
import PrivacyPolicy from "./Components/StaticContent/PrivacyPolicy";
import AppBanner from "./Components/Common/AppBanner";
import ContactUs from "./Components/ContactUs/ContactUs";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <Navbar />
              <Hero />
              <About />
              <Features />
              <Technology />
              <Testimonials />
              <AppBanner />
              <ContactUs />
            </div>
          }
        ></Route>
        <Route path="/terms" element={<TandC />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      </Routes>
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default App;
