import React from "react";
import footerLogo from "../../assets/org-logo.png";
import "./Footer.css";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/" || location.pathname === "/home";

  return (
    <div className="footer container">
      <div className="footer-text container">
        <div className="footer-logo">
          <img src={footerLogo} alt="logo" className="footerLogo" />
        </div>
        <div className="horizontal-line"></div>
        {isHomePage ? (
          <div className="ul footer-nav-links common-paragraph">
            <div className="li">
              <Link to="hero" smooth={true} offset={-125} delay={500}>
                Home
              </Link>
            </div>
            <div className="li">
              <Link to="about" smooth={true} offset={-125}>
                About
              </Link>
            </div>
            <div className="li">
              <Link to="features" smooth={true} offset={-125}>
                Features
              </Link>
            </div>
            <div className="li">
              <Link to="technology" smooth={true} offset={-125}>
                Technology
              </Link>
            </div>
            <div className="li">
              <Link to="testimonials" smooth={true} offset={-125}>
                Testimonials
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="footer-end">
          <div className="common-paragraph2">
            © 2024 Fitnex | All rights reserved.
          </div>
          <div className="footer-end-links common-paragraph4">
            <a
              href="/privacy-policy"
              className="li"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="/terms"
              className="li"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            {/* <div className="li">Cookies Settings</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
